import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="relative pb-12">
      <div className="absolute z-0 md:z-10 inset-0 opacity-10 pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3724"
          height="609"
          viewBox="0 0 3724 609"
        >
          <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
            <g transform="translate(1209)">
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
            <g transform="matrix(1 0 0 -1 2482 609)">
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
            <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
            <circle cx="642" cy="158" r="20" fill="#00B1A9" />
            <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
            <circle cx="131" cy="250" r="34" fill="#00B1A9" />
            <circle cx="403" cy="562" r="19" fill="#00B1A9" />
            <circle cx="707" cy="253" r="35" fill="#00B1A9" />
            <circle cx="285" cy="199" r="43" fill="#00B1A9" />
            <circle cx="129" cy="74" r="28" fill="#75DDDD" />
            <circle cx="928" cy="256" r="35" fill="#508991" />
            <circle cx="477" cy="279" r="34" fill="#00B1A9" />
            <circle cx="57" cy="532" r="42" fill="#75DDDD" />
            <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
            <circle cx="172" cy="558" r="23" fill="#00B1A9" />
            <circle cx="20" cy="278" r="20" fill="#508991" />
            <circle cx="671" cy="550" r="26" fill="#508991" />
            <circle cx="367" cy="168" r="11" fill="#75DDDD" />
            <circle cx="840" cy="48" r="48" fill="#00B1A9" />
            <circle cx="261" cy="482" r="20" fill="#508991" />
            <circle cx="828" cy="50" r="29" fill="#508991" />
            <circle cx="891" cy="467" r="40" fill="#75DDDD" />
            <circle cx="442" cy="214" r="18" fill="#508991" />
            <circle cx="536" cy="264" r="29" fill="#508991" />
            <circle cx="104" cy="344" r="49" fill="#75DDDD" />
            <circle cx="703" cy="571" r="38" fill="#508991" />
            <circle cx="278" cy="313" r="33" fill="#00B1A9" />
            <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
            <circle cx="58" cy="374" r="50" fill="#75DDDD" />
            <circle cx="602" cy="465" r="42" fill="#00B1A9" />
            <circle cx="608" cy="507" r="29" fill="#508991" />
            <circle cx="555" cy="109" r="13" fill="#00B1A9" />
            <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
            <circle cx="221" cy="413" r="36" fill="#00B1A9" />
            <circle cx="1042" cy="165" r="18" fill="#508991" />
            <circle cx="666" cy="279" r="30" fill="#75DDDD" />
            <circle cx="1063" cy="100" r="26" fill="#508991" />
            <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
            <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
            <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
            <circle cx="287" cy="560" r="12" fill="#508991" />
            <circle cx="119" cy="238" r="24" fill="#75DDDD" />
            <circle cx="328" cy="571" r="14" fill="#508991" />
            <circle cx="888" cy="456" r="38" fill="#508991" />
            <circle cx="906" cy="73" r="16" fill="#00B1A9" />
            <circle cx="799" cy="257" r="41" fill="#00B1A9" />
            <circle cx="965" cy="367" r="11" fill="#75DDDD" />
            <circle cx="504" cy="50" r="12" fill="#75DDDD" />
            <circle cx="891" cy="379" r="21" fill="#75DDDD" />
            <circle cx="629" cy="218" r="11" fill="#508991" />
            <circle cx="757" cy="500" r="40" fill="#75DDDD" />
          </g>
        </svg>
      </div>
      <div className="relative pt-64 max-w-3xl text-center mx-auto">
        <h1 className="font-semibold text-2xl md:text-4xl text-white">
          404<span className="text-brand-grey-dark">.</span>
        </h1>
      </div>
    </div>
    <div className="relative flex flex-col">
      <div className="w-full bg-brand-teal absolute top-0 z-0">
        <svg
          viewBox="0 0 50 15"
          preserveAspectRatio="none"
          className="w-full bottom-0 h-32 md:h-76"
          style={{ marginBottom: "-1px" }}
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M0,14.9954241 C19.9497878,5.17408693 35.0012698,0.175684169 49.965204,0 C49.9695893,5.37969228 50.0383731,14.9954241 49.9695893,14.9954241 C38.8635973,14.9954241 22.2070676,14.9954241 0,14.9954241 Z"
              fill="#E8FFFE"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      </div>
      <div className="h-full w-full pb-16 bg-brand-teal-light">
        <div className="max-w-4xl mx-auto relative px-4 md:px-0 h-64"></div>
      </div>
      <div className="bg-brand-teal-light -mb-1">
        <svg
          version="1.1"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className="h-24 w-full"
        >
          <g fill="#101821">
            <path d="M0 100 C40 0 60 0 100 100 Z"></path>
          </g>
        </svg>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
